import { createRouter } from "vue-router";
import { useAppStore } from 'Common/stores/appStore';
import MainRoutes from '@/routes/main';

let router;

let appStore;

switch(window.location.hostname){
    default: {
        router = createRouter(MainRoutes);
        router.beforeEach((to, from, next) => {
            if(!appStore) appStore = useAppStore();
            appStore.checkWelcomeAnalyzeNowModals(to, from);
            next();
        })
    }
}

export default router;
