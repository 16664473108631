import axios from "axios";

/**
 * Sends a GET request using axios with the provided URL and query parameters.
 *
 * @param {string} url - The URL to send the GET request to.
 * @param {Object} queryParams - An object representing the query parameters to include in the request.
 * @returns {Promise} - A Promise that resolves to the response of the GET request.
 */
async function get(url, queryParams) {
    const query = Object.keys(queryParams || {})
        .filter(key => {

            const value = queryParams[key];
            if (value === undefined || value === null) {
                return false;
            }

            if (typeof value === 'string' && value.trim() === '') {
                return false;
            }

            if (value instanceof Array && value.length === 0) {
                return false;
            }

            return true;
        })
        .map(key => {

            let value = queryParams[key];

            if (value instanceof Array) {
                value = value.join(',');
            }

            return `${key}=${encodeURIComponent(value)}`
        })
        .join('&');

    return await axios.get(`${url}?${query}`);
}

const axiosHelper = {
    get
}

export default axiosHelper;
