<template>
  <InvalidProperty v-if="!propsValidation.isValid" :message="propsValidation.message" />
  <template v-else>
    <div class="analysis-tags">
      <div class="analysis-tags-title">{{Locale.tags}}:</div>
      <div class="analysis-tags-container">
        <div v-for="tag in tagsObj" class="analysis-tag" :class="{['analysis-tag-'+tag.className]: true}">
          {{tag.name}}
        </div>
      </div>
    </div>
  </template>
</template>

<script>
const propsDefinition = {
  tags: {
    required: true,
    typeof: "array"
  },
  localeTexts: {
    required: false,
    typeof: "object",
    existProps: ['tags'],
  },
  language: {
    required: false,
  }
}
</script>
<script setup>
import InvalidProperty from '@/components/InvalidProperty.vue';
import {getDefinePropsObject, getLocaleTexts, validateProps} from "@/helpers/helper.js";
import {computed} from "vue";

const stringifyProps = defineProps(getDefinePropsObject(propsDefinition));

const {propsValidation, props} = validateProps(propsDefinition, stringifyProps);
const Locale = getLocaleTexts(props, (locale) => locale.analysisReport);

const tagsObj = computed(() => {
  return props.value.tags.filter(i => typeof i === 'string')
    .filter(i => i.toLowerCase().indexOf('priority') === -1)
    .map(i => {
      return {
        name: i,
        className: keys.find((key) => i.toLowerCase().indexOf(key) > -1) || 'unknown-tag'
      }
    })
    .slice(0, 9);
})

const keys = [
  'upset', 'joy', 'interest', 'stress', 'confidence',
  'hesitation', 'aggression', 'dissatisfaction',
  'frustration', 'argument', 'free-talker', 'sadness',
  'sad', 'emotional', 'logical', 'excitement', 'passion',
  'uneasy', 'energy', 'priority', 'uncertainty'
];

</script>

<style src="./CallTags.scss" lang="scss"/>
