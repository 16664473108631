import {defineStore} from 'pinia';
import axios from "axios";
import {environment} from 'Common/environments/environment';

export const useSiteStore = defineStore('siteStore', {
    state: () => {
        return {
            cloudAnalysis: [],
            sites: [],
            stats: null,
            typesOfUse: [],
            useCases: [],
        }
    },
    getters: {

    },
    actions: {
        getSites(organizationExternalId, showArchived) {
            const url = this.buildUrlWithQuery(environment.sitesServiceUrl + `/sites`, { organizationExternalId, showArchived });
            return axios.get(url).then(response => {
                if(!response.data.success)
                    throw new Error(response.data.error || "Failed to get sites.");
                this.sites = response.data.data.items;
                return (response.data.data || []);
            });
        },

        addSite(data) {
            return axios.post(environment.sitesServiceUrl + '/sites', {data}).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                this.sites.push(response.data.data);
                return response.data.data;
            });
        },

        updateSite(data) {
            return axios.put(environment.sitesServiceUrl + `/sites/${data.siteExternalId}`, {data}).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                const site = this.sites.find(s => s.siteExternalId === data.siteExternalId);
                return Object.assign(site || {}, data);
            });
        },

        updateSiteStatus(siteExternalId, status) {
            return axios.post(environment.sitesServiceUrl + '/sites/updateSiteStatus', {
                siteExternalId,
                status
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                const site = this.sites.find(s => s.siteExternalId === siteExternalId);
                if (site) {
                    site.status = status;
                }
            });
        },

        deleteSite(siteExternalId) {
            return axios
              .delete(environment.sitesServiceUrl + `/sites/${siteExternalId}`)
              .then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                this.sites = this.sites.filter((item) => item.siteExternalId !== siteExternalId);
            });
        },

        siteInfo(externalId) {
            return axios.get(environment.sitesServiceUrl + `/sites/${externalId}`).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return (response.data.data || {});
            });
        },

        getSiteStats() {
            return axios.post(environment.sitesServiceUrl + '/sites/stats').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.stats = (response.data.data || {});
            });
        },

        getSiteById(siteExternalId){
            const site = this.sites.find((s) => s.siteExternalId === siteExternalId);
            if(site) return Promise.resolve(site);

            return this.siteInfo(siteExternalId);
        },

        loadTypesOfUse () {
            if(this.typesOfUse.length > 0) return Promise.resolve(this.typesOfUse);

            return axios.post(environment.sitesServiceUrl + '/sites/typesOfUse').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.typesOfUse = (response.data.data || []);
            });
        },

        loadUseCases () {
            if(this.useCases.length > 0) return Promise.resolve(this.useCases);

            return axios.post(environment.sitesServiceUrl + '/sites/useCases').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.useCases = (response.data.data || []);
            });
        },

        loadDocker(dockerExternalId, filter) {
            const url = this.buildUrlWithQuery(environment.sitesServiceUrl + `/dockers/${dockerExternalId}`, { ...filter });
            return axios.get(url).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return (response.data.data || []);
            });
        },
        loadDockers(siteExternalId, apiKeyExternalId) {
            const url = this.buildUrlWithQuery(environment.sitesServiceUrl + `/dockers`, { siteExternalId, apiKeyExternalId });
            return axios.get(url).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return (response.data.data.items || []);
            });
        },

        getDockersReportUrl() {
            return environment.sitesServiceUrl + '/dockers/report';
        },

        loadDockersReport(filter, params) {
            return axios.post(this.getDockersReportUrl(),{
                data: {
                    filter,
                    params
                }
            }).then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return (response.data.data || []);
            });
        },
        getCloudAnalysisUrl() {
            return environment.sitesServiceUrl + '/dockers/cloudAnalysis';
        },
        getCloudAnalysis(data) {
            return axios.post(this.getCloudAnalysisUrl(), {
                data
            }).then(response => {
                if(!response.data.success)
                    throw new Error(response.data.error || "Failed to get sites.");
                this.cloudAnalysis = response.data?.data?.items || [];
                return (response.data.data || []);
            });
        },

        getSitesFullList() {
            return axios.post(environment.sitesServiceUrl + '/sites/fullList').then(response => {
                if(!response.data.success)
                    throw new Error(response.data.error || "Failed to get sites.");
                return (response.data.data || []);
            });
        },

        async getUserActivity() {
            const response = await axios.post(environment.sitesServiceUrl + '/dockers/getUserActivity');

            if (!response.data.success) {
                return Promise.reject(response.data);
            }

            return response.data.data;
        },

        buildUrlWithQuery(baseUrl, params) {
            const queryString = Object.keys(params)
                .filter(key => Boolean(params[key]))
                .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
                .join('&');
            return `${baseUrl}?${queryString}`;
        }
}
})
